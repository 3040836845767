export default function InputCurrency({
  className,
  inputclassName,
  type,
  label,
  placeholder,
  required,
  error,
  errorMessage,
  defaultValue,
  value,
  disabled,
  onChange,
  inputText
}) {
  return (
    <div className={`relative ${className === undefined ? '' : className}`}>
      {label === undefined ? '' : <label className="inline-block mb-1 text-black-900 dark:text-white">{label}{required === true ? <span className="text-danger"> *</span> : ''}</label>}
      <div className="relative flex">
        <input
          type={type}
          className={`${inputclassName === undefined ? '' : inputclassName} w-full transition pe-12 ps-4 py-3 rounded-xl ${disabled === true ? 'text-black-700 dark:text-black-500 bg-black-300' : 'text-black-900 dark:text-white hover:ring-2 focus:ring-2 ring-primary/75 bg-black-100'} dark:bg-white/5 focus:outline-none`}
          placeholder={placeholder}
          required={required}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        <span className="absolute end-0 pe-6 flex items-center h-full font-medium">{inputText}</span>
      </div>
      {error ? <div class="text-sm text-danger mt-1">{errorMessage}</div> : ''}
    </div>
  );
}