import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../services/auth/AuthContext";
// import ButtonLink from '../../../components/buttons/ButtonLink';
import Input from '../../../components/inputs/Input';

const ErrorBox = ({ message }) => {
    return (
        <div className="md:fixed top-0 py-5 bg-danger w-full text-center text-white text-sm">
            {message}
        </div>
    );
}

export default function Login() {
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = () => {
        //mockup
        dispatch({ type: "LOGIN", payload: { username: "mockup" } });
        navigate("/");
    }

    return (
        <main className={`min-h-screen overflow-hidden flex flex-col pt-16 md:pt-20`}>
            <div className="flex-grow flex flex-col justify-center items-center">
                {/* <ErrorBox
                    message={`We couldn't find an account matching the username or email you entered. Please check your username or email and try again.`}
                /> */}
                <div className="flex-grow md:flex-grow-0 w-full p-6 md:p-12 max-w-lg flex flex-col justify-center gap-6 md:drop-shadow-main md:border rounded-3xl bg-white border-black-100 dark:bg-white/5 dark:border-black-900">
                    <h1 className="text-3xl text-center font-bold text-black-900 dark:text-white">ลงชื่อเข้าใช้</h1>
                    <div className="flex flex-col gap-2">
                        <label className="font-semibold text-black-900 dark:text-white" htmlFor="login-user-input">ชื่อผู้ใช้หรืออีเมล</label>
                        <Input
                            id="login-user-input"
                            type="text"
                            placeholder=""
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between">
                            <label className="font-semibold text-black-900 dark:text-white" htmlFor="login-password-input">รหัสผ่าน</label>
                            {/* <Link href="/forgot-password" className="text-sm underline text-black-900 dark:text-white hover:text-link">ลืมรหัสผ่าน?</Link> */}
                        </div>
                        <Input
                            id="login-password-input"
                            type="password"
                            placeholder=""
                        />
                    </div>
                    {/* <ButtonLink
                        color={'primary'}
                        href="/"
                        text="เข้าสู่ระบบ"
                    /> */}
                    <button
                        type="button"
                        onClick={handleLogin}
                        className={`inline-block transition text-center px-4 py-3 font-medium text-white bg-primary hover:bg-primary-hover rounded-xl`}
                    >
                        เข้าสู่ระบบ
                    </button>
                    <div className="text-center text-black-900 dark:text-white">
                        ไม่มีบัญชี?<Link to="/register" className="underline hover:text-link">สมัครสมาชิก</Link>
                    </div>
                </div>
            </div>
        </main>
    )
}