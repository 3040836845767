import { Link } from "react-router-dom";

export default function ButtonLink({ href, text, className, color }) {

    let buttonColors = "";
    if (color === 'primary') {
        buttonColors = "text-white bg-primary hover:bg-primary-hover";
    }

    let buttonClassName = "";
    if (className !== undefined) {
        buttonClassName = className;
    }

    return (
        <Link
            to={href}
            className={`inline-block transition text-center px-4 py-3 font-medium ${buttonColors} ${buttonClassName} rounded-xl`}
        >
            {text}
        </Link>
    );
}