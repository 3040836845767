const Input = ({ id, type, placeholder }) => {
    return (
        <input
            id={id}
            type={type}
            className="w-full transition px-4 py-3 rounded-xl hover:ring-2 focus:ring-2 focus:outline-none ring-primary/75 bg-black-100 dark:bg-white/5"
            placeholder={placeholder}
        />
    );
}

export default Input;