import Result from './Result'
import btc from '../../../../assets/images/currencys/btc.png'
import eth from '../../../../assets/images/currencys/eth.png'

const start = [
  {
    "title": "ทุนเริ่มต้น",
    "first": {
      "name": "Bitcoin",
      "short": "BTC",
      "icon": btc,
      "result": 0.748129
    },
    "second": {
      "name": "Ethereum",
      "short": "ETH",
      "icon": eth,
      "result": 9.651889
    },
    "port": 30000
  },
];

const current = [
  {
    "title": "ปัจจุบัน",
    "first": {
      "name": "Bitcoin",
      "short": "BTC",
      "icon": btc,
      "result": 0.655498
    },
    "second": {
      "name": "Ethereum",
      "short": "ETH",
      "icon": eth,
      "result": 10.878214
    },
    "port": 37625.77,
    "result": "P",
  },
];

export default function AutoRebalance() {
  return (
    <div>
      <h2 className="text-xl text-black-900 dark:text-white font-semibold mb-4">ทดสอบปรับสมดุลอัตโนมัติย้อนหลัง</h2>
      <div className="flex flex-col gap-4">
        <Result data={start} />
        <Result data={current} />
      </div>
    </div>
  );
}