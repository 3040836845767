const history = [
  {
    date: "1 ก.ย. 2566",
    coin_price: 11861.84,
    money_invested: 100,
    port_value: 100,
    sum_change: 0,
    percen_Change: 0.00,
    type: null,
  },
  {
    date: "2 ก.ย. 2566",
    coin_price: 11300.4,
    money_invested: 200,
    port_value: 195.27,
    sum_change: -4.73,
    percen_Change: -2.37,
    type: "N",
  },
  {
    date: "3 ก.ย. 2566",
    coin_price: 10197.46,
    money_invested: 300,
    port_value: 276.21,
    sum_change: -23.79,
    percen_Change: -7.93,
    type: "N",
  },
  {
    date: "4 ก.ย. 2566",
    coin_price: 11861.84,
    money_invested: 400,
    port_value: 380.13,
    sum_change: -19.87,
    percen_Change: -4.97,
    type: "N",
  },
  {
    date: "5 ก.ย. 2566",
    coin_price: 10938.00,
    money_invested: 500,
    port_value: 502.03,
    sum_change: 2.03,
    percen_Change: 0.41,
    type: "P",
  },
];

export default function DCAHistory() {

  return (
    <div>
      <h2 className="text-xl text-black-900 dark:text-white font-semibold mb-4">ประวัติการเทรด</h2>
      <div className="overflow-auto">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">#</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">วันที่</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">ราคาเหรียญ</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">เงินที่ลงทุน</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-end">มูลค่าพอร์ต</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-end">เปลี่ยนแปลง</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-end">% เปลี่ยนแปลง</th>
            </tr>
          </thead>
          <tbody>
            {history.map((data, index) => (
              <tr className="odd:bg-slate-100 even:bg-transparent dark:odd:bg-slate-100/5" key={index}>
                <td className="px-3 text-black-900 dark:text-white py-2 text-start">{index + 1}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 text-start whitespace-nowrap">{data.date}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 text-start">{data.coin_price.toLocaleString("en-EN", { style: "currency", currency: "USD" })}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 text-start">{data.money_invested.toLocaleString("en-EN", { style: "currency", currency: "USD" })}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 text-end">{data.port_value.toLocaleString("en-EN", { style: "currency", currency: "USD" })}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 text-end">{data.sum_change.toLocaleString("en-EN", { style: "currency", currency: "USD" })}</td>
                <td className={`px-3 py-2 text-end ${data.type === 'P' ? "text-success" : data.type === 'N' ? 'text-danger' : 'text-black-900 dark:text-white '}`}>
                  {data.type === 'P' ? "+" : ''}{data.percen_Change}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
