import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import { Link } from "react-router-dom";
import DCABacktest from "./dca/DCABacktest";
import DCAHistory from "./dca/DCAHistory";
import RebalanceBacktest from "./rebalance/RebalanceBacktest";
import AutoRebalance from "./rebalance/AutoRebalance";
import Hold from "./rebalance/Hold";
import RebalanceHistory from "./rebalance/RebalanceHistory";

export default function Backtest() {
  return (
    <main className={`main h-screen`}>
      <Sidebar />
      <div className="content">
        <Header pageTitle={'Backtest'} />
        <div className="flex flex-col gap-6 p-6">
          <div className="flex justify-center py-4 bg-white dark:bg-black-900 rounded-xl xl:rounded-2xl">
            <div className="grid grid-cols-2 w-72 bg-black-200 dark:bg-white/10 rounded-full">
              <span className="text-center font-medium py-3 px-6 bg-primary rounded-full text-white">DCA</span>
              <Link to="#" className="text-center font-medium py-3 px-6 rounded-full text-black-700 dark:text-black-600 hover:text-primary dark:hover:text-primary">Rebalancing</Link>
            </div>
          </div>
          {/* DCA */}
          <div>
            <div className="flex flex-col gap-12 p-4 lg:p-6 bg-white dark:bg-black-900 rounded-xl xl:rounded-2xl">
              <DCABacktest />
            </div>
            <div className="flex flex-col gap-12 p-4 lg:p-6 bg-white dark:bg-black-900 rounded-xl xl:rounded-2xl">
              <DCAHistory />
            </div>
          </div>
          {/* Rebalance */}
          <div>
            <div className="flex flex-col gap-6">
              <div className="p-4 lg:p-6 bg-white dark:bg-black-900 rounded-xl xl:rounded-2xl">
                <RebalanceBacktest />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6 bg-white dark:bg-black-900 rounded-xl xl:rounded-2xl">
                <AutoRebalance />
                <Hold />
              </div>
              <div className="p-4 lg:p-6 bg-white dark:bg-black-900 rounded-xl xl:rounded-2xl">
                <RebalanceHistory />
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>
  )
}