const history = [
  {
    sum_rebalance: 30000,
    sum_hold: 30000,
    current_price: {
      "first": { 'currency': "BTC", 'amount': 20050.02 },
      "second": { 'currency': "ETH", 'amount': 1554.10 },
    },
    amount: {
      "first": { 'currency': "BTC", 'amount': 20050.02 },
      "second": { 'currency': "ETH", 'amount': 1554.10 },
    }
  },
  {
    sum_rebalance: 30370.37,
    sum_hold: 30370.37,
    current_price: {
      "first": { 'currency': "BTC", 'amount': 20131.46 },
      "second": { 'currency': "ETH", 'amount': 1586.16 },
    },
    amount: {
      "first": { 'currency': "BTC", 'amount': 0.754292 },
      "second": { 'currency': "ETH", 'amount': 9.573435 },
    },
  },
  {
    sum_rebalance: 30000,
    sum_hold: 30000,
    current_price: {
      "first": { 'currency': "BTC", 'amount': 20050.02 },
      "second": { 'currency': "ETH", 'amount': 1554.10 },
    },
    amount: {
      "first": { 'currency': "BTC", 'amount': 20050.02 },
      "second": { 'currency': "ETH", 'amount': 1554.10 },
    }
  },
  {
    sum_rebalance: 30370.37,
    sum_hold: 30370.37,
    current_price: {
      "first": { 'currency': "BTC", 'amount': 20131.46 },
      "second": { 'currency': "ETH", 'amount': 1586.16 },
    },
    amount: {
      "first": { 'currency': "BTC", 'amount': 0.754292 },
      "second": { 'currency': "ETH", 'amount': 9.573435 },
    },
  },
  {
    sum_rebalance: 30000,
    sum_hold: 30000,
    current_price: {
      "first": { 'currency': "BTC", 'amount': 20050.02 },
      "second": { 'currency': "ETH", 'amount': 1554.10 },
    },
    amount: {
      "first": { 'currency': "BTC", 'amount': 20050.02 },
      "second": { 'currency': "ETH", 'amount': 1554.10 },
    }
  },
  {
    sum_rebalance: 30370.37,
    sum_hold: 30370.37,
    current_price: {
      "first": { 'currency': "BTC", 'amount': 20131.46 },
      "second": { 'currency': "ETH", 'amount': 1586.16 },
    },
    amount: {
      "first": { 'currency': "BTC", 'amount': 0.754292 },
      "second": { 'currency': "ETH", 'amount': 9.573435 },
    },
  },
];

export default function DCAHistory() {

  return (
    <div>
      <h2 className="text-xl text-black-900 dark:text-white font-semibold mb-4">ประวัติการเทรด</h2>
      <div className="overflow-auto">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">ยอดรวมปรับสมดุล</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">ถือรวม</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">ราคาปัจจุบัน</th>
              <th className="p-3 text-black-600 font-semibold whitespace-nowrap text-start">จำนวน</th>
            </tr>
          </thead>
          <tbody>
            {history.map((data, index) => (
              <tr className="odd:bg-slate-100 even:bg-transparent dark:odd:bg-slate-100/5" key={index}>
                <td className="px-3 text-black-900 dark:text-white py-2 whitespace-nowrap text-start">{data.sum_rebalance}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 whitespace-nowrap text-start">{data.sum_hold}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 whitespace-nowrap text-start">{`${data.current_price.first.currency} ${data.current_price.first.amount} | ${data.current_price.second.currency} ${data.current_price.second.amount}`}</td>
                <td className="px-3 text-black-900 dark:text-white py-2 whitespace-nowrap text-start">{`${data.amount.first.currency} ${data.amount.first.amount} | ${data.amount.second.currency} ${data.amount.second.amount}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
