export default function InputCheckBoxSpecify({
  className,
  inputclassName,
  options,
  inputText,
  id,
  checked,
  onChange,
  value,
  defaultValue,
  placeholder
}) {
  return (
    <div className={`relative ${className === undefined ? '' : className}`}>
      <input type="radio" class="absolute invisible peer" name={options} id={id} checked={checked} />
      <label
        class="inline-block w-full transition cursor-pointer text-center px-4 py-3 rounded-xl border border-transparent font-medium text-black-900 dark:text-white bg-black-100 dark:bg-white/5 hover:bg-black-300 peer-checked:bg-primary/10 peer-checked:border-primary after:content-[''] after:w-full after:absolute after:top-0 after:start-0 after:flex after:h-full peer-checked:after:hidden"
        for={id}
      >
        <div className="relative flex">
          <input
            type="text"
            className={`${inputclassName === undefined ? '' : inputclassName} w-full transition pe-14 rounded-xl text-black-900 dark:text-white bg-transparent focus:outline-none`}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
          />
          <span className="absolute end-0 flex items-center h-full font-medium">{inputText}</span>
        </div>
      </label>
    </div>
  );
}