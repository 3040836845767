export default function Result({ data }) {

  if (data.length === 0) {
    return <p>No data available</p>;
  }

  const resultData = data[0];

  return (
    <div className="flex gap-3 flex-col p-4 border dark:border-black-800 rounded-xl">
      <h3 className="text-center text-xl font-bold text-black-900 dark:text-white">{resultData.title}</h3>
      <div className="grid gap-3 grid-cols-2">
        <div className="flex gap-3 flex-col items-center bg-slate-100 dark:bg-white/5 rounded-xl px-4 py-6">
          <img
            src={resultData.first.icon}
            width={48}
            height={48}
            alt={resultData.first.name}
          />
          <p className="font-medium text-black-900 dark:text-white">{resultData.first.result} {resultData.first.short}</p>
        </div>
        <div className="flex gap-3 flex-col items-center bg-slate-100 dark:bg-white/5 rounded-xl px-4 py-6">
          <img
            src={resultData.second.icon}
            width={48}
            height={48}
            alt={resultData.second.name}
          />
          <p className="font-medium text-black-900 dark:text-white">{resultData.second.result} {resultData.second.short}</p>
        </div>
      </div>
      <div className={`px-4 py-5 rounded-xl ${resultData.result === "P" ? 'bg-emerald-100 text-emerald-500'
          : resultData.result === "N" ? 'bg-rose-100 text-rose-500'
            : 'bg-slate-100 dark:bg-white/5 text-black-900 dark:text-white'}`}>
        <h4 className="text-lg text-center font-bold text-inherit dark:text-inherit">พอตรวม {resultData.port} USDT</h4>
      </div>
    </div>
  );
}