import { Link } from "react-router-dom";
import banner1x1 from '../../assets/images/banners/1x1.jpg';
import banner2x1 from '../../assets/images/banners/2x1.jpg';
import banner3x2 from '../../assets/images/banners/3x2.jpg';

export default function Banner({ href, title, size }) {

  let banner = "";
  if (size === '3x2') {
    banner = banner3x2;
  } else if (size === '2x1') {
    banner = banner2x1;
  } else {
    banner = banner1x1;
  }

  return (
    <Link
      to={href}
      className={`inline-block transition rounded-xl overflow-hidden`}
    >
      <img src={banner} className="w-full" alt={title}/>
    </Link>
  );
}