"use client"
import React from 'react'
import Select from 'react-select'
import './styles.css'

export default function SelectCurrency({
  className,
  label,
  required,
  options,
  typing,
  defaultValue
}) {
  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      padding: 0,
      backgroundColor: 'transparent',
      display: 'flex',
      gap: 8,
      ':before': {
        content: '" "',
        display: 'block',
        width: 24,
        height: 24,
        backgroundImage: `url(${state.data.icon})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      gap: 8,
      ':before': {
        content: '" "',
        display: 'block',
        width: 24,
        heaight: 24,
        backgroundImage: `url(${state.data.icon})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
      }
    }),
  };

  return (
    <div className={`relative ${className === undefined ? '' : className}`}>
      {label === undefined ? '' : <label className="inline-block mb-1 text-black-900 dark:text-white">{label}{required === true ? <span className="text-danger"> *</span> : ''}</label>}
      <Select
        isSearchable={typing}
        options={options}
        defaultValue={defaultValue}
        className="select-default-container select-currency"
        classNamePrefix="select-default"
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#f3f6ff',
            primary: '#6366F1',
          },
        })}
      />
    </div>
  );
}