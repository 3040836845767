import { Link } from "react-router-dom";

import btc from "../../assets/images/currencys/btc.png";
import eth from "../../assets/images/currencys/eth.png";

const history = [
    {
      name: "Bitcoin",
      short_name: "BTC",
      src: btc,
      amount: "+0.00045734 BTC",
      date: "3 ก.ย. 2566",
    },
    {
      name: "Ethereum",
      short_name: "ETH",
      src: eth,
      amount: "+0.00034576 BTC",
      date: "3 ก.ย. 2566",
    },
    {
      name: "Bitcoin",
      short_name: "BTC",
      src: btc,
      amount: "+0.00045734 BTC",
      date: "1 ก.ย. 2566",
    },
  ];
  
  export default function Wallet() {
    return (
      <div className="flex flex-col gap-4 p-6 rounded-xl bg-white dark:bg-black-900">
        <div className="flex justify-between items-center">
          <h2 className="text-xl text-black-900 dark:text-white font-semibold">กระเป๋าเงินของฉัน</h2>
          <Link href={'#'} className="text-primary hover:text-primary-hover">รายละเอียด</Link>
        </div>
        <div className="block p-6 rounded-xl bg-primary/5">
          <p className="text-center mb-2 text-black-900 dark:text-black-500">สินทรัพย์รวม</p>
          <div className="text-center text-2xl font-bold text-black-900 dark:text-white">1.88888888 BTC</div>
        </div>
        <div className="flex flex-col px-2">
          {history.map((data, index) => (
            <div key={index} className="flex gap-3 items-center py-3 border-b border-black-200 dark:border-black-800">
              <img
                src={data.src}
                width={40}
                height={40}
                alt={data.name}
              />
              <div className="flex flex-col">
                <h3 className="text-black-900 dark:text-white font-bold">ได้รับ {data.name}</h3>
                <p className="text-sm"><span className="text-primary font-medium">{data.amount}</span> เมื่อวันที่ {data.date}</p>
              </div>
            </div>
          ))}
          <div className="pt-4">
            <Link href={'#'} className="block text-primary hover:text-primary-hover text-center">รายละเอียดกระเป๋าเงิน</Link>
          </div>
        </div>
      </div>
    );
  }
  