import InputCurrency from '../../../../components/inputs/InputCurrency'
import SelectDefault from '../../../../components/selects/SelectDefault'
import SelectCurrency from '../../../../components/selects/SelectCurrency'
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';

const periodOptions = [
  { value: 0, label: '1 เดือน' },
  { value: 1, label: '3 เดือน' },
  { value: 2, label: '6 เดือน' },
  { value: 3, label: '1 ปี' },
  { value: 4, label: '2 ปี' },
  { value: 5, label: '5 ปี' },
  { value: 6, label: '10 ปี' },
];

const frequencyOptions = [
  { value: 0, label: 'ทุกวัน' },
  { value: 1, label: 'ทุกสัปดาห์' },
  { value: 2, label: '2 สัปดาห์ / ครั้ง' },
  { value: 3, label: 'ทุกเดือน' },
];

const currencyOptions = [
  { value: "BTC", label: 'Bitcoin', icon: "/assets/images/currency/btc.png" },
  { value: "ETH", label: 'Ethereum', icon: "/assets/images/currency/eth.png" },
  { value: "USDT", label: 'Tether', icon: "/assets/images/currency/usdt.png" },
  { value: "BNB", label: 'BNB', icon: "/assets/images/currency/bnb.png" },
  { value: "XRP", label: 'XRP', icon: "/assets/images/currency/xrp.png" },
];

const chartOption = {
  grid: {
    left: 40,
    top: 30,
    right: 5,
    bottom: 20
  },
  tooltip: {
    trigger: 'axis',
    valueFormatter: (value) => '$' + value.toFixed(2)
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    },
    data: ['1 ก.ย. 66', '2 ก.ย. 66', '3 ก.ย. 66', '4 ก.ย. 66', '5 ก.ย. 66', '6 ก.ย. 66', '7 ก.ย. 66'],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '${value}'
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    }
  },
  series: [
    {
      name: 'เงินที่ลงทุน',
      type: 'line',
      color: '#d0d1fb',
      seriesLayoutBy: 'row',
      data: [100, 200, 300, 400, 500, 600, 700],
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(99, 102, 241, 0.1)'
          },
          {
            offset: 1,
            color: 'rgb(99, 102, 241, 0.05)'
          }
        ])
      },
    },
    {
      name: 'มูลค่าพอร์ต',
      type: 'line',
      color: '#4044ee',
      seriesLayoutBy: 'row',
      data: [100, 195.27, 276.21, 380.13, 502.03, 611.46, 724.13]
    },
  ]
};

export default function DCABacktest() {
  return (
    <div className="flex flex-col gap-8">
      <div className="py-3 grid grid-cols-3 gap-3">
        <div className="col-span-3 lg:col-span-1 grid grid-cols-2 gap-3">
          <SelectDefault
            label="เริ่มต้น"
            typing={false}
            options={periodOptions}
            defaultValue={periodOptions[3]}
          />
          <SelectDefault
            label="สิ้นสุด"
            typing={false}
            options={periodOptions}
            defaultValue={periodOptions[3]}
          />
        </div>
        <div className="col-span-3 lg:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-3">
          <InputCurrency
            type="text"
            label="จำนวนเงินทุน"
            inputText="$"
            defaultValue={(100000).toLocaleString()}

          />
          <SelectCurrency
            label="เหรียญ"
            typing={true}
            options={currencyOptions}
            defaultValue={currencyOptions[0]}
          />
          <SelectDefault
            label="ความถี่ในการเทรด"
            typing={false}
            options={frequencyOptions}
            defaultValue={frequencyOptions[0]}
          />
        </div>
      </div>
      <div style={{ height: 360 }}>
        <ReactEcharts option={chartOption} style={{ height: '100%' }} />
      </div>

      <div className="bg-primary-hover p-4 rounded-3xl">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
          <div className="block text-center p-4 border-2 border-white rounded-xl">
            <h5 className="text-3xl lg:text-4xl font-bold text-white mb-3">$20,000.00</h5>
            <p className="text-xl font-medium text-white">เงินที่ลงทุน</p>
          </div>
          <div className="flex flex-col gap-3 items-center justify-center">
            <svg className="text-white rotate-90 md:rotate-0" width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.607052 33.7367C-0.185566 34.506 -0.204464 35.7722 0.564841 36.5648C1.33415 37.3574 2.60034 37.3763 3.39295 36.607L20.393 20.107C20.781 19.7304 21 19.2127 21 18.6719C21 18.1311 20.781 17.6134 20.393 17.2367L3.39295 0.736716C2.60033 -0.0325904 1.33415 -0.0136924 0.56484 0.778925C-0.204465 1.57154 -0.185567 2.83773 0.607051 3.60704L16.1284 18.6719L0.607052 33.7367ZM21.6071 33.7367C20.8144 34.506 20.7955 35.7722 21.5648 36.5648C22.3341 37.3574 23.6003 37.3763 24.393 36.607L41.393 20.107C41.781 19.7304 42 19.2127 42 18.6719C42 18.1311 41.781 17.6134 41.393 17.2367L24.393 0.736716C23.6003 -0.0325904 22.3341 -0.0136924 21.5648 0.778925C20.7955 1.57154 20.8144 2.83773 21.6071 3.60704L37.1284 18.6719L21.6071 33.7367Z" fill="currentColor" />
            </svg>
            <div className="text-lg text-lemon leading-6 text-center font-semibold">
              <div>20.00%</div>
              <div>+7,000.00</div>
            </div>
          </div>
          <div className="block text-center p-4 border-2 border-white rounded-xl">
            <h5 className="text-3xl lg:text-4xl font-bold text-white mb-3">$21,000.00</h5>
            <p className="text-xl font-medium text-white">มูลค่าพอร์ต</p>
          </div>
        </div>
      </div>
    </div>
  );
}
