import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";

export default function DollarCostAveraging() {
  return (
    <main className={`main h-screen`}>
      <Sidebar />
      <div className="content">
        <Header pageTitle={'Dollar-Cost Averaging'} />
        <div className="p-6">
          <h1>Dollar-Cost Averaging</h1>
        </div>
      </div>

    </main>
  )
}