import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./services/auth/AuthContext";
import { useContext } from "react";

import Login from "./pages/unprotected/login/Login";
import Dashboard from "./pages/protected/dashboard/Dashboard";
import Rebalancing from "./pages/protected/rebalancing/Rebalancing";
import DollarCostAveraging from "./pages/protected/dca/DollarCostAveraging";
import Backtest from "./pages/protected/backtest/Backtest";
import Package from "./pages/protected/package/Package";
import Invoices from "./pages/protected/invoices/Invoices";

export default function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* This is Unprotected Routes for everyone */}
          <Route path="login" element={<Login />} />

          {/* This is Protected Routes with Authentication required */}
          <Route path="/">
            <Route
              index
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="rebalancing"
              element={
                <RequireAuth>
                  <Rebalancing />
                </RequireAuth>
              }
            />
            <Route
              path="dca"
              element={
                <RequireAuth>
                  <DollarCostAveraging />
                </RequireAuth>
              }
            />
            <Route
              path="package"
              element={
                <RequireAuth>
                  <Package />
                </RequireAuth>
              }
            />
            <Route
              path="invoices"
              element={
                <RequireAuth>
                  <Invoices />
                </RequireAuth>
              }
            />
            <Route
              path="backtest"
              element={
                <RequireAuth>
                  <Backtest />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};