import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import Avatar from '../../components/avatar/Avatar';
import avatar from '../../assets/images/avatars/5.png';

const IconSunny = ({ className }) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}><path fill="currentColor" d="M12.006 6C13.5973 6 15.1234 6.63214 16.2487 7.75736C17.3739 8.88258 18.006 10.4087 18.006 12C18.006 13.5913 17.3739 15.1174 16.2487 16.2426C15.1234 17.3679 13.5973 18 12.006 18C10.4147 18 8.88859 17.3679 7.76337 16.2426C6.63815 15.1174 6.00601 13.5913 6.00601 12C6.00601 10.4087 6.63815 8.88258 7.76337 7.75736C8.88859 6.63214 10.4147 6 12.006 6ZM12.006 8.4C11.0512 8.4 10.1356 8.77928 9.46043 9.45442C8.7853 10.1295 8.40601 11.0452 8.40601 12C8.40601 12.9548 8.7853 13.8705 9.46043 14.5456C10.1356 15.2207 11.0512 15.6 12.006 15.6C12.9608 15.6 13.8765 15.2207 14.5516 14.5456C15.2267 13.8705 15.606 12.9548 15.606 12C15.606 11.0452 15.2267 10.1295 14.5516 9.45442C13.8765 8.77928 12.9608 8.4 12.006 8.4ZM12.006 0L14.874 4.104C13.986 3.78 13.014 3.6 12.006 3.6C10.998 3.6 10.026 3.78 9.13801 4.104L12.006 0ZM1.61401 6L6.60601 5.58C5.88601 6.192 5.23801 6.936 4.73401 7.8C4.20601 8.688 3.90601 9.6 3.73801 10.548L1.61401 6ZM1.63801 18L3.75001 13.476C3.91801 14.4 4.24201 15.336 4.74601 16.2C5.25001 17.088 5.89801 17.832 6.60601 18.444L1.63801 18ZM22.386 6L20.262 10.548C20.094 9.6 19.77 8.676 19.266 7.8C18.762 6.936 18.126 6.18 17.406 5.568L22.386 6ZM22.374 18L17.406 18.432C18.114 17.82 18.75 17.064 19.254 16.2C19.758 15.324 20.082 14.4 20.25 13.452L22.374 18ZM12.006 24L9.11401 19.872C10.002 20.196 10.974 20.4 12.006 20.4C12.99 20.4 13.962 20.196 14.85 19.872L12.006 24Z" /></svg>
}
const IconMoon = ({ className }) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}><path fill="currentColor" d="M18.9984 3.52849L16.1059 5.74379L17.1463 9.23802L14.1394 7.17117L11.1325 9.23802L12.1729 5.74379L9.28037 3.52849L12.9275 3.42572L14.1394 0L15.3513 3.42572L18.9984 3.52849ZM23 11.4191L21.125 12.8464L21.7995 15.1074L19.8559 13.7714L17.9123 15.1074L18.5869 12.8464L16.7118 11.4191L19.067 11.362L19.8559 9.13524L20.6448 11.362L23 11.4191ZM20.3933 17.0715C21.3422 16.9801 22.3598 18.3276 21.7538 19.184C21.3879 19.6979 20.9992 20.1775 20.519 20.6342C16.0487 25.1219 8.81161 25.1219 4.35273 20.6342C-0.117578 16.1808 -0.117578 8.94112 4.35273 4.48769C4.81006 4.03093 5.29024 3.61984 5.80473 3.25443C6.66221 2.64922 8.0113 3.66552 7.91984 4.6133C7.61115 7.87915 8.70872 11.2706 11.224 13.7714C13.7278 16.2836 17.112 17.3798 20.3933 17.0715ZM18.5183 19.3781C15.2827 19.1954 12.0815 17.8594 9.60049 15.4157C7.11953 12.915 5.7933 9.7062 5.61037 6.48602C2.39769 10.0716 2.52345 15.5756 5.96479 19.0241C9.41756 22.4613 14.9283 22.5869 18.5183 19.3781Z" /></svg>
}
const IconWindow = ({ className }) => {
  return <svg width="24" height="24" viewBox="0 0 448 512" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}><path fill="currentColor" d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" /></svg>
}

const uInfo = {
  uAvatar: avatar,
  uName: "Verse AutoBot",
}

const DropdownProfile = () => {
  return (
    <div className="absolute top-full end-0 w-[280px] overflow-hidden bg-white border border-black-100 dark:bg-black-900 dark:border-black-800 rounded-xl drop-shadow-lg">
      <div className="p-4 flex gap-3">
        <Avatar uAvatar={uInfo.uAvatar} uName={uInfo.uName} />
      </div>
    </div>
  );
}

function Header({ pageTitle }) {
  const option = [
    {
      icon: <IconMoon />,
      text: "light",
    },
    {
      icon: <IconSunny />,
      text: "dark",
    },
    {
      icon: <IconWindow />,
      text: "system",
    },
  ];

  const [theme, setTheme] = useState(
    typeof window !== 'undefined' && localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : "system"
  );

  useEffect(() => {
    const element = document.documentElement;
    const darkQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkQuery.addEventListener("change", (e) => {
      if (!("theme" in localStorage)) {
        if (e.matches) {
          element.classList.add("dark");
        } else {
          element.classList.remove("dark");
        }
      }
    });

    function onWindowMatch() {
      if (localStorage.theme === 'dark' || (!("theme" in localStorage) && darkQuery.matches)) {
        element.classList.add("dark");
      } else {
        element.classList.remove("dark");
      }
    }

    switch (theme) {
      case 'dark':
        element.classList.add('dark');
        localStorage.setItem('theme', 'dark');
        break;
      case 'light':
        element.classList.remove('dark');
        localStorage.setItem('theme', 'light');
        break;
      default:
        localStorage.removeItem('theme');
        onWindowMatch();
        break;
    }
  }, [theme]);

  return (
    <>
      <header className="px-6 backdrop-blur-2xl sticky start-0 end-0 top-0 z-40 bg-white/90 dark:bg-black-900/90">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">{pageTitle}</h1>
          <div className="flex items-center">
            <div className="relative h-10 overflow-hidden px-2 border-r dark:border-black-800">
              <div className="swich-group">
                {option?.map(opt => (
                  <button
                    key={opt.text}
                    type="button"
                    className={`h-10 w-10 flex justify-center items-center transition rounded-full ${'swich-' + opt.text}`}
                    onClick={() => setTheme(opt.text)}
                  >
                    {opt.icon}
                  </button>
                ))}
              </div>
            </div>
            <button type="button" className="flex gap-2 justify-center items-center h-10 px-4 border-r dark:border-black-800">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 12H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg><span>≈</span> 1.88888888 BTC
            </button>
            <button type="button" className="flex justify-center items-center h-10 px-4 border-r dark:border-black-800">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.35395 21C10.0591 21.6224 10.9853 22 11.9998 22C13.0142 22 13.9405 21.6224 14.6456 21M17.9998 8C17.9998 6.4087 17.3676 4.88258 16.2424 3.75736C15.1172 2.63214 13.5911 2 11.9998 2C10.4085 2 8.88235 2.63214 7.75713 3.75736C6.63192 4.88258 5.99977 6.4087 5.99977 8C5.99977 11.0902 5.22024 13.206 4.34944 14.6054C3.6149 15.7859 3.24763 16.3761 3.2611 16.5408C3.27601 16.7231 3.31463 16.7926 3.46155 16.9016C3.59423 17 4.19237 17 5.38863 17H18.6109C19.8072 17 20.4053 17 20.538 16.9016C20.6849 16.7926 20.7235 16.7231 20.7384 16.5408C20.7519 16.3761 20.3846 15.7859 19.6501 14.6054C18.7793 13.206 17.9998 11.0902 17.9998 8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="19" cy="5" r="3.75" fill="#FB3766" stroke="white" strokeWidth="1.5" />
              </svg>
            </button>
            <div className="py-5 relative">
              <div className="d-flex h-10 ps-3">
                <button type="button" className="w-10 h-10">
                  <Avatar uAvatar={uInfo.uAvatar} uName={uInfo.uName} />
                </button>
              </div>
              {/* <DropdownProfile /> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
