import InputCheckBox from '../../../../components/inputs/InputCheckBox'
import InputCheckBoxSpecify from '../../../../components/inputs/InputCheckBoxSpecify'
import SelectCurrency from '../../../../components/selects/SelectCurrency'
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';

const periodOptions = [
  { options: 'period', title: "1 ปี" },
  { options: 'period', title: "6 เดือน" },
  { options: 'period', title: "3 เดือน" },
  { options: 'period', title: "1 เดือน" },
  { options: 'period', title: "1 สัปดาห์" },
];

const currencyOptions = [
  { value: "BTC", label: 'Bitcoin (BTC)', icon: "/assets/images/currency/btc.png" },
  { value: "ETH", label: 'Ethereum (ETH)', icon: "/assets/images/currency/eth.png" },
  { value: "USDT", label: 'Tether (USDT)', icon: "/assets/images/currency/usdt.png" },
  { value: "BNB", label: 'BNB', icon: "/assets/images/currency/bnb.png" },
  { value: "XRP", label: 'XRP', icon: "/assets/images/currency/xrp.png" },
];

const chartOption = {
  grid: {
    left: 50,
    top: 20,
    right: 5,
    bottom: 20
  },
  tooltip: {
    trigger: 'axis',
    valueFormatter: (value) => '$' + value.toFixed(2)
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    },
    data: ['1 ก.ย. 66', '2 ก.ย. 66', '3 ก.ย. 66', '4 ก.ย. 66', '5 ก.ย. 66', '6 ก.ย. 66', '7 ก.ย. 66', '8 ก.ย. 66', '9 ก.ย. 66', '10 ก.ย. 66'],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '${value}'
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    }
  },
  series: [
    {
      name: 'ยอดรวมปรับสมดุล',
      type: 'line',
      color: '#4044ee',
      seriesLayoutBy: 'row',
      data: [1000, 1005, 1010, 1015, 1020, 1025, 1030, 1035, 1040, 1050],
      areaStyle: {
        opacity: 0.8,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(99, 102, 241, 0.3)'
          },
          {
            offset: 1,
            color: 'rgb(99, 102, 241, 0)'
          }
        ])
      },
    }
  ]
};

export default function DCABacktest() {
  return (
    <div className="flex flex-col gap-4">
      <div className="block">
        <h2 className="text-black-900 dark:text-white font-semibold mb-2">เลือกระยะเวลาที่จะทดสอบ</h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
          {periodOptions.map((data, index) => (
            <InputCheckBox
              options={data.options}
              id={data.options + (index + 1)}
              text={data.title}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className="block">
        <h2 className="text-black-900 dark:text-white font-semibold mb-2">เลือกจำนวนเงินทุนที่ใช้ทดสอบ</h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
          <InputCheckBox
            options="funds"
            id="funds1"
            text="30,000 USDT"
          />
          <InputCheckBox
            options="funds"
            id="funds2"
            text="10,000 USDT"
          />
          <InputCheckBox
            options="funds"
            id="funds3"
            text="5,000 USDT"
          />
          <InputCheckBox
            options="funds"
            id="funds4"
            text="3,000 USDT"
          />
          <InputCheckBoxSpecify
            options="funds"
            id="funds5"
            inputText="USDT"
            placeholder={'ระบุจำนวน'}
            inputclassName="text-end"
          />
        </div>
      </div>
      <div className="block">
        <h2 className="text-black-900 dark:text-white font-semibold mb-2">เลือกคู่เหรียญที่ต้องการทดสอบ</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <SelectCurrency
            typing={true}
            options={currencyOptions}
            defaultValue={currencyOptions[0]}
          />
          <SelectCurrency
            typing={true}
            options={currencyOptions}
            defaultValue={currencyOptions[1]}
          />
        </div>
      </div>
      <div style={{ height: 360 }}>
        <ReactEcharts option={chartOption} style={{ height: '100%' }} />
      </div>
    </div>
  );
}
