export default function InputCheckBox({
  className,
  options,
  text,
  id,
  checked
}) {
  return (
    <div className={`relative ${className === undefined ? '' : className}`}>
      <input type="radio" class="absolute invisible peer" name={options} id={id} checked={checked} />
      <label
        class="inline-block w-full transition cursor-pointer text-center px-4 py-3 rounded-xl border border-transparent font-medium text-black-900 dark:text-white bg-black-100 dark:bg-white/5 hover:bg-black-300 peer-checked:bg-primary/10 peer-checked:border-primary"
        for={id}
      >
        {text}
      </label>
    </div>
  );
}