"use client"
import React from 'react'
import Select from 'react-select'
import './styles.css'

export default function SelectDefault({
  className,
  label,
  required,
  options,
  typing,
  defaultValue
}) {
  return (
    <div className={`relative ${className === undefined ? '' : className}`}>
      {label === undefined ? '' : <label className="inline-block mb-1 text-black-900 dark:text-white">{label}{required === true ? <span className="text-danger"> *</span> : ''}</label>}
      <Select
        isSearchable={typing}
        options={options}
        defaultValue={defaultValue}
        className="select-default-container"
        classNamePrefix="select-default"
        // menuIsOpen={true}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#eff0fe',
            primary: '#6366F1',
          },
        })}
      />
    </div>
  );
}