import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";
import PackageBox from '../../../components/widget/PackageBox'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const packageData = [
  {
    name: "ทดลอง",
    price: 0,
    benefits: [
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
    ]
  },
  {
    name: "เริ่มต้น",
    price: 10000,
    benefits: [
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
    ]
  },
  {
    name: "ทั่วไป",
    price: 20000,
    benefits: [
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
      { benefit: "Lorem Ipsum is simply", status: false },
    ]
  },
  {
    name: "โปร",
    price: 30000,
    benefits: [
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
      { benefit: "Lorem Ipsum is simply", status: true },
    ]
  }
];

export default function DollarCostAveraging() {
  return (
    <main className={`main h-screen`}>
      <Sidebar />
      <div className="content">
        <Header pageTitle={'Package'} />
        <div className="p-6">
          <PackageBox data={packageData} />
        </div>
      </div>

    </main>
  )
}