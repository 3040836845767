import ButtonLink from '../buttons/ButtonLink'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const IconTrue = ({ className }) => {
  return (

    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="currentColor" fillOpacity="0.2" />
      <path d="M6 12L10 16L18 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
const IconFalse = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="currentColor" fillOpacity="0.2" />
      <path d="M16 8L8 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 8L16 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default function PackageBox({ data }) {

  return (
    <div className="-mx-4">
      <Swiper
        spaceBetween={0}
        slidesPerView={'auto'}
      >
        {data.map((data, index) => (
          <SwiperSlide key={index} className="!w-[82%] md:!w-[45%] lg:!w-[32%] xl:!w-3/12 !px-3">
            <div className="group block transition duration-300 px-6 pb-6 pt-8 rounded-2xl bg-white dark:bg-black-900">
              <h2 className="text-center text-3xl font-bold transition duration-300 text-primary dark:text-primary group-hover:text-primary-hover mb-6">{data.name}</h2>
              <div className="flex gap-2 justify-center mb-6">
                {data.price === 0 ? '' : <span className="flex text-2xl font-bold">฿</span>}
                <span className="text-4xl text-black-900 dark:text-white font-black">{data.price === 0 ? 'ฟรี' : data.price.toLocaleString()}</span>
                {data.price === 0 ? '' : <span className="flex items-end text-xl font-bold"> / ปี</span>}
              </div>
              <div className="flex flex-col gap-3 mb-8">
                {data.benefits.map((data, index) => (
                  <div key={index} className="flex gap-3 items-center">
                    {data.status === true ? <IconTrue className="text-success" /> : <IconFalse className="text-black-500 dark:text-black-600" />}<span>{data.benefit}</span>
                  </div>
                ))}
              </div>
              <ButtonLink
                href="#"
                color="primary"
                text="เลือก"
                className="w-full"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
