import { useContext } from "react";
import { AuthContext } from "../../../services/auth/AuthContext";
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts';
import Wallet from "../../../components/wallets/wallet";
import Banner from "../../../components/banners/Banner";
import Sidebar from "../../../components/layout/Sidebar";
import Header from "../../../components/layout/Header";


const CaretUp = () => { return (<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 320 512"><path fill="currentColor" d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" /></svg>) }
const CaretDown = () => { return (<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 320 512"><path fill="currentColor" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg>) }

const portData = [
  {
    title: "จำนวนพอต Rebalance ทั้งหมด",
    amount: 1
  },
  {
    title: "จำนวนพอต DCA ทั้งหมด",
    amount: 2
  }
];

const fundsData = [
  {
    title: "จำนวนเงินที่เริ่มต้นในพอต Rebalance ทั้งหมด",
    amount: 5260,
  },
  {
    title: "จำนวนเงินที่เริ่มต้นในพอต DCA ทั้งหมด",
    amount: 7472,
  },
];

const balanceData = [
  {
    title: "จำนวนเงินทเติบโตในพอต Rebalance ทั้งหมด",
    amount: 5260,
    type: 'P',
    result: 1.37
  },
  {
    title: "จำนวนเงินที่เติบโตในพอต DCA ทั้งหมด",
    amount: 7472,
    type: 'N',
    result: 0.12
  }
];

const Info = () => {
  return (
    <div className="grid gap-4 grid-cols-2">
      {portData.map((data, index) => (
        <div key={index} className="flex flex-col gap-3 p-6 rounded-xl bg-white dark:bg-black-900">
          <div className="flex gap-3 items-center font-medium">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.66602" y="10" width="3.33333" height="8.33333" rx="1.66667" fill="#5235E8" />
              <rect x="8.33398" y="6.25" width="3.33333" height="12.0833" rx="1.66667" fill="#5235E8" fillOpacity="0.6" />
              <rect x="15" y="1.6665" width="3.33333" height="16.6667" rx="1.66667" fill="#5235E8" fillOpacity="0.2" />
            </svg>
            <h2 className="text-black-900 dark:text-black-500">{data.title}</h2>
          </div>
          <div className="flex items-center text-4xl font-bold text-black-900 dark:text-white">{data.amount} Port</div>
        </div>
      ))}
      {fundsData.map((data, index) => (
        <div key={index} className="flex flex-col gap-3 p-6 rounded-xl bg-white dark:bg-black-900">
          <div className="flex gap-3 items-center font-medium">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.66602" y="10" width="3.33333" height="8.33333" rx="1.66667" fill="#5235E8" />
              <rect x="8.33398" y="6.25" width="3.33333" height="12.0833" rx="1.66667" fill="#5235E8" fillOpacity="0.6" />
              <rect x="15" y="1.6665" width="3.33333" height="16.6667" rx="1.66667" fill="#5235E8" fillOpacity="0.2" />
            </svg>
            <h2 className="text-black-900 dark:text-black-500">{data.title}</h2>
          </div>
          <div className="flex items-center text-4xl font-bold text-black-900 dark:text-white">${data.amount.toLocaleString()}</div>
        </div>
      ))}
      {balanceData.map((data, index) => (
        <div key={index} className="flex flex-col gap-3 p-6 rounded-xl bg-white dark:bg-black-900">
          <div className="flex gap-3 items-center font-medium">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.66602" y="10" width="3.33333" height="8.33333" rx="1.66667" fill="#5235E8" />
              <rect x="8.33398" y="6.25" width="3.33333" height="12.0833" rx="1.66667" fill="#5235E8" fillOpacity="0.6" />
              <rect x="15" y="1.6665" width="3.33333" height="16.6667" rx="1.66667" fill="#5235E8" fillOpacity="0.2" />
            </svg>
            <h2 className="text-black-900 dark:text-black-500">{data.title}</h2>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center text-4xl font-bold text-black-900 dark:text-white">${data.amount.toLocaleString()}</div>
            <div className={`flex gap-1 items-center text-sm font-medium px-2 py-1 rounded-full 
              ${data.type === 'P' ? 'text-success bg-success/10' : 'text-danger bg-danger/10'}`}>
              {data.type === 'P' ? <CaretUp /> : <CaretDown />}<span>{data.type === 'P' ? '+' : '-'}{data.result}</span>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
}

const graphicLinearGradient = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  {
    offset: 0,
    color: 'rgb(99, 102, 241, 0.3)'
  },
  {
    offset: 1,
    color: 'rgb(99, 102, 241, 0)'
  }
])

const chartOption = {
  grid: {
    left: 50,
    top: 20,
    right: 30,
    bottom: 20
  },
  tooltip: {
    trigger: 'axis',
    valueFormatter: (value) => {
      if (value > 0) {
        return '$' + value.toFixed(2);
      } else {
        return '';
      }
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    },
    data: ['1 ก.ย. 66', '2 ก.ย. 66', '3 ก.ย. 66', '4 ก.ย. 66', '5 ก.ย. 66', '6 ก.ย. 66', '7 ก.ย. 66', '8 ก.ย. 66', '9 ก.ย. 66', '10 ก.ย. 66'],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: '${value}'
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: false
    }
  },
  series: [
    {
      name: 'Rebalance',
      type: 'line',
      color: '#4044ee',
      seriesLayoutBy: 'row',
      data: [1000, 1005, 1010, 1015, 1020, 1025, 1030, 1035, 1040, 1050],
      areaStyle: {
        opacity: 0.8,
        color: graphicLinearGradient,
      },
    },
    {
      name: 'DCA 1',
      type: 'line',
      color: '#4044ee',
      seriesLayoutBy: 'row',
      data: [100, 202, 306, 410, 508, 606, 709, 812, 914, 1016],
      areaStyle: {
        opacity: 0.8,
        color: graphicLinearGradient,
      },
    },
    {
      name: 'DCA 2',
      type: 'line',
      color: '#4044ee',
      seriesLayoutBy: 'row',
      data: [null, null, null, 100, 200, 300, 400, 500, 600, 700],
      areaStyle: {
        opacity: 0.8,
        color: graphicLinearGradient,
      },
    }
  ]
};


const Graph = () => {
  return (
    <div className="flex flex-col gap-3 px-6 py-10 rounded-xl bg-white dark:bg-black-900">
      <div style={{ height: 300 }}>
        <ReactEcharts option={chartOption} style={{ height: '100%' }} />
      </div>
    </div>
  );
}

export default function Dashboard() {
  const { dispatch } = useContext(AuthContext);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  }

  return (
    <main className={`main h-screen`}>
      <Sidebar />
      <div className="content">
        <Header pageTitle={'Dashboard'} />
        <div className="p-6">
          <div className="grid gap-6 2xl:gap-12 grid-cols-3">
            <div className="col-span-2 flex flex-col gap-6">
              <Info />
              <Graph />
            </div>
            <div className="col-span-1 flex flex-col gap-6">
              <Wallet />
              <Banner size="3x2" />
            </div>
          </div>
        </div>
      </div>


      {/* <h1>หน้าแรกจ้า</h1>
            <button
                type="button"
                onClick={handleLogout}
                className={`inline-block transition text-center px-4 py-3 font-medium text-white bg-primary hover:bg-primary-hover rounded-xl`}
            >
                ออกจากระบบ
            </button> */}
    </main>
  )
}